import React from "react";
import { readData } from "../../utils/common";
import IconButton from "../common/IconButton";
import { defaultDef } from "./legDefinition";

export const icons = {
  OpenLeg: "expand_more",
  CloseLeg: "expand_less",
  AddLeg: "add",
  RemoveLeg: "close",
  Share: "share",
  Search: "search",
  Locked: "lock",
  Unlocked: "lock_open",
  Linked: "link",
  Unlinked: "link_off",
  More: "more_horiz",
};

export const showLinkedButton = (leg, onLegChange, fieldSet, idx) => {
  let value = readData(leg, fieldSet.data);
  let fieldOpts = defaultDef("linkToLast", onLegChange);
  let linked = leg && value ? "Linked" : "Unlinked";

  //   return (
  //     <Fragment>
  //       {leg && value
  //         ? iconButton(idx, "Linked", fieldOpts, false, {})
  //         : iconButton(idx, "Unlinked", fieldOpts, true, {
  //             color: "default",
  //           })}
  //     </Fragment>
  //   );

  return (
    <IconButton
      idx={idx}
      icons={icons}
      icon={linked}
      fieldOpts={fieldOpts}
      value={linked === "Unlinked"}
      opts={linked === "Linked" ? {} : { color: "default" }}
    />
  );
};

export const showLockedButton = (leg, onLegChange, fieldSet, idx) => {
  let value = readData(leg, fieldSet.data);
  let locked = leg && value ? "Locked" : "Unlocked";

  return (
    <IconButton
      idx={idx}
      icons={icons}
      icon={locked}
      fieldOpts={defaultDef("locked", onLegChange)}
      value={locked === "Unlocked"}
      opts={locked === "Locked" ? {} : { color: "default" }}
    />
  );

  //   return (
  //     <Fragment>
  //       {leg && value
  //         ? iconButton(
  //             idx,
  //             "Locked",
  //             defaultDef("locked", onLegChange),
  //             false,
  //             {}
  //           )
  //         : iconButton(idx, "Unlocked", defaultDef("locked", onLegChange), true, {
  //             color: "default",
  //           })}
  //     </Fragment>
  //   );
};

export const showExpandButton = (bShowRow, idx, legId, showHideRow) => (
  <IconButton
    idx={idx}
    icons={icons}
    icon={bShowRow ? "CloseLeg" : "OpenLeg"}
    fieldOpts={{ onchange: showHideRow }}
    value={legId}
    opts={{}}
  />
);

//   <Fragment>
//     {iconButton(
//       idx,
//       bShowRow ? "CloseLeg" : "OpenLeg",
//       { onchange: showHideRow },
//       legId,
//       {}
//     )}
//   </Fragment>

export const showMoreLegButton = (legId, idx, showHideMoreLeg) => (
  <IconButton
    idx={idx}
    icons={icons}
    icon={"More"}
    fieldOpts={{ onchange: showHideMoreLeg }}
    value={legId}
    opts={{}}
  />
);
//   return (
//     <Fragment>
//       {iconButton(idx, "More", { onchange: showHideMoreLeg }, legId, {})}
//     </Fragment>
//   );
// return "";
// return iconButton(
//   "00",
//   "More",
//   {
//     onchange: () => showHideMoreLeg(legId),
//   },
//   legId,
//   {}
// );
// };
