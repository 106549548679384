import React, { useReducer } from "react";
import axios from "axios";
import { v4 as uuid } from "uuid";

import HolidayContext from "./holidayContext";
import holidayReducer from "./holidayReducer";

import {
  GET_HOLIDAYS,
  GET_HOLIDAY_EDITLIST,
  // ADD_HOLIDAY,
  // UPDATE_MEMBER,
  // DELETE_HOLIDAY,
  CLEAR_HOLIDAYS,
  // UPDATE_HOLIDAY,
  GET_LOCATION_REFERENCE,
  // FILTER_HOLIDAYS,
  SET_CURRENT_HOLIDAY,
  CLEAR_CURRENT_HOLIDAY,
  // CLEAR_FILTER,
  HOLIDAY_ERROR,
  CLEAR_HOLIDAY_ERRORS,
  HOLIDAY_ALERT,
  SET_HOLIDAY_ALERT,
  REMOVE_HOLIDAY_ALERT,
  REMOVE_ALL_HOLIDAY_ALERTS,
  CLEAR_EDITLIST,
} from "../typesHolidays";
import { dbMsg } from "../../utils/common";

const dp = "con.ste.holiday";
const dbl = 1;

const HolidayState = (props) => {
  const initialState = {
    holidayAlerts: [],
    holidays: null,
    editList: null,
    currentHoliday: null,
    currentDay: null,
    loading: true,
    countries: null,
    cities: null,
    airports: null,
    error: null,
  };

  const [state, dispatch] = useReducer(holidayReducer, initialState);

  // Set Alert
  const setHolidayAlert = (msg, type, timeout = 5000) => {
    const id = uuid();

    dispatch({
      type: SET_HOLIDAY_ALERT,
      payload: { msg, type, id },
    });
    setTimeout(
      () =>
        dispatch({
          type: REMOVE_HOLIDAY_ALERT,
          payload: id,
        }),
      timeout
    );
  };

  //
  // Set Alert
  const clearHolidayAlert = () => {
    dispatch({
      type: REMOVE_ALL_HOLIDAY_ALERTS,
    });
  };

  // Load User
  const getHolidays = async (force) => {
    // if token exists, set token in config
    try {
      // try getting user from auth
      const res = await axios.get("/api/holidays");

      if (res.data) {
        // send user to reducer and mark as authenticated
        dispatch({
          type: GET_HOLIDAYS,
          payload: res.data,
        });
      } else {
        // send user to reducer and mark as authenticated
        dispatch({
          type: CLEAR_HOLIDAYS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({ type: HOLIDAY_ERROR });
    }
  };

  // Login User
  const addHoliday = async (holiday, legs) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post(
        "/api/holidays/add",
        { holiday, legs },
        config
      );

      if (res.data?.msg && res.data.msg === "Error") {
        // send to reducer to log token in local storage
        //   and mark as authenticated
        dispatch({
          type: HOLIDAY_ERROR,
          payload: res.data.msg,
        });
      }
      setHolidayAlert("Data Saved", "success");
      setCurrentHoliday(holiday);
    } catch (err) {
      dispatch({
        type: HOLIDAY_ERROR,
        payload: err.response.data.msg,
      });
      setHolidayAlert("Error Adding", "danger");
    }
  };

  // Login User
  const updateHoliday = async (holiday, legs) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post(
        "/api/holidays/update/",
        { holiday, legs },
        config
      );

      if (res.data?.msg) {
        // send to reducer to log token in local storage
        //   and mark as authenticated
        dispatch({
          type: HOLIDAY_ALERT,
          payload: res.data.msg,
        });
      }
      setCurrentHoliday(holiday);
      setHolidayAlert("Data Saved", "success");
    } catch (err) {
      dispatch({
        type: HOLIDAY_ERROR,
        payload: err.response.data.msg,
      });
      setHolidayAlert("Error Saving Data", "danger");
    }
  };

  const getEditList = async (holidayId) => {
    let lm = dp + ".getEditList: ";

    // if token exists, set token in config
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      dbMsg(1, dbl, lm + "reading edit list");
      dbMsg(1, dbl, lm + "holidayId: " + holidayId);
      // try registering user with form data and json config
      const res = await axios.post(
        "/api/holidays/editlist/",
        { holidayId },
        config
      );

      dbMsg(1, dbl, lm + "read data");
      dbMsg(1, dbl, lm + !res.data ? "no data" : "has data");

      if (res.data) {
        // send user to reducer and mark as authenticated
        dispatch({
          type: GET_HOLIDAY_EDITLIST,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_HOLIDAY_EDITLIST,
          payload: [],
        });
      }
    } catch (err) {
      console.log("got error");
      dispatch({ type: HOLIDAY_ERROR });
    }
  };

  // Login User
  const updateMembers = async (action, params) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { holidayId, userId, newContact } = params;

    try {
      // try registering user with form data and json config
      // const res = await axios
      await axios
        .post(
          "/api/holidays/updateMembers",
          { action, holidayId, userId, newContact },
          config
        )
        .then((res) => {
          // console.log(res.data);
          // setCurrentHoliday(res.data);
          getEditList(holidayId);
          // get
        });

      // // send to reducer to log token in local storage
      // //   and mark as authenticated
      // dispatch({
      //   type: UPDATE_MEMBER,
      //   payload: res.data,
      // });

      // // load user and return user details
      // loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: HOLIDAY_ERROR,
      });
    }
  };

  // Clear Errors
  const clearEditList = () => {
    dispatch({ type: CLEAR_EDITLIST });
  };

  // Load User
  const getLocationReferenceData = async (search, scope) => {
    // if token exists, set token in config
    try {
      const params = new URLSearchParams([
        ["search", search],
        ["scope", scope],
      ]);

      // try getting user from auth
      const res = await axios.get("/api/holidays/reference/locations", {
        params,
      });

      if (res.data) {
        // send user to reducer and mark as authenticated
        dispatch({
          type: GET_LOCATION_REFERENCE,
          payload: res.data,
        });
      }
    } catch (err) {
      console.log("got error");
      dispatch({ type: HOLIDAY_ERROR });
    }
  };

  const setCurrentHoliday = async (data) => {
    let lm = dp + ".setCurrentHoliday: ";

    try {
      let res;
      if (data?._id === "new") {
        res = { data: data };
      } else {
        res = await axios.get(`/api/holidays/one/${data._id}`);
      }

      if (res.data) {
        // send user to reducer and mark as authenticated
        dispatch({
          type: SET_CURRENT_HOLIDAY,
          payload: res.data,
        });
      } else {
        // send user to reducer and mark as authenticated
        dispatch({
          type: CLEAR_CURRENT_HOLIDAY,
          payload: res.data,
        });
      }
    } catch (err) {
      console.error(lm, " got error");
      dispatch({ type: HOLIDAY_ERROR });
    }
  };

  const clearCurrentHoliday = () => {
    dispatch({ type: CLEAR_CURRENT_HOLIDAY });
  };

  // Clear Errors
  const clearHolidayErrors = () => {
    dispatch({ type: CLEAR_HOLIDAY_ERRORS });
  };

  return (
    <HolidayContext.Provider
      value={{
        holidayAlerts: state.holidayAlerts,
        clearHolidayAlert,
        setHolidayAlert,
        getHolidays,
        addHoliday,
        updateMembers,
        updateHoliday,
        holidays: state.holidays,
        getEditList,
        clearEditList,
        editList: state.editList,
        setCurrentHoliday,
        clearCurrentHoliday,
        currentHoliday: state.currentHoliday,
        loading: state.loading,
        error: state.error,
        getLocationReferenceData,
        countries: state.countries,
        cities: state.cities,
        airports: state.airports,
        clearHolidayErrors,
      }}
    >
      {props.children}
    </HolidayContext.Provider>
  );
};

export default HolidayState;
