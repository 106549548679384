import React, { Fragment, useContext, useEffect, useState } from "react";
import { checkIfMobile } from "../../utils/common";

import {
  updateCurrentLeg,
  updateLaterLegs,
  addTravelLeg,
} from "./legListFuncs";

import HolidayContext from "../../context/holiday/holidayContext";
import LegContext from "../../context/leg/legContext";
import LegItem from "./LegItem";

import Spinner from "../layout/Spinner";
import ShowTotalsRow from "./ShowTotalsRow";

const LegList = ({ holiday, defaultLeg, legsNew, setLegsNew }) => {
  const holidayContext = useContext(HolidayContext);
  const { currentHoliday } = holidayContext;

  const legContext = useContext(LegContext);
  const { legs, clearLegs, loading } = legContext;

  const [showLeg, setShowLeg] = useState({});
  const [showMoreLeg, setShowMoreLeg] = useState({});
  const IS_MOBILE = checkIfMobile();

  useEffect(() => {
    if (currentHoliday) {
      if (currentHoliday?._id === "new") {
        clearLegs();
        setLegsNew([{ ...defaultLeg, _id: "new-1" }]);
        setShowLeg({ ...showLeg, "new-1": true });
      } else if (holiday?._id) {
        if (legs?.[0]) setLegsNew(legs);
      }
    } else {
      if (currentHoliday === null) clearLegs();
    }
    // eslint-disable-next-line
  }, [currentHoliday, legs]);

  // adds a new travel leg
  const addLeg = (e, idx) => {
    let newLegId = addTravelLeg(e, idx, legsNew, setLegsNew);
    setShowLeg({ ...showLeg, [newLegId._id]: true });
  };

  // removes a specifc travel leg
  const removeLeg = (e, idx) => {
    let newValues = [...legsNew];
    newValues.splice(idx, 1);
    // updateBothLegs(newValues, true);
    setLegsNew(newValues);
  };

  // updates the current & later legs when a change to the following fields is updated
  //    date from, date to, duration
  const onLegChange = (e, idx) => {
    updateCurrentLeg(e, idx, legsNew, setLegsNew, showLeg, setShowLeg);
    updateLaterLegs(e, idx, legsNew, setLegsNew);
  };

  // does action when clicking search.
  //   opens search result for that destination & type in a new window
  const onLinkClick = (e) => {
    let href = e.target.value;
    window.open(href);
  };

  const showHideRow = (e, idx) => {
    let legId = e.target.value;
    setShowLeg({
      ...showLeg,
      [legId]: !showLeg?.[legId] ? true : !showLeg?.[legId],
    });

    // if (!showLeg?.[legId]) {
    //   setShowLeg({ ...showLeg, [legId]: true });
    // } else {
    //   setShowLeg({ ...showLeg, [legId]: !showLeg[legId] });
    // }
  };

  const showHideMoreLeg = (e, idx) => {
    let legId = e.target.value;
    if (!showMoreLeg?.[legId]) {
      setShowMoreLeg({ ...showMoreLeg, [legId]: true });
    } else {
      setShowMoreLeg({ ...showMoreLeg, [legId]: !showMoreLeg[legId] });
    }
  };

  const checkToShowOrNot = (showHide, setShowHide, leg) => {
    let bShow = false;
    if (showHide?.[leg._id] === undefined || showHide?.[leg._id] === null) {
      setShowHide({ ...showHide, [leg._id]: false });
    } else {
      bShow = showHide[leg._id];
    }
    return bShow;
  };

  const displayLegList = (data) => {
    return (
      <Fragment>
        {data.map((leg, idx) => (
          <LegItem
            IS_MOBILE={IS_MOBILE}
            key={["legI", "legItem", idx, leg.legType].join("-")}
            leg={leg}
            idx={idx}
            onLegChange={onLegChange}
            addLeg={addLeg}
            removeLeg={removeLeg}
            onTypeChange={onLegChange}
            onLinkClick={onLinkClick}
            showHideRow={showHideRow}
            bShowRow={checkToShowOrNot(showLeg, setShowLeg, leg)}
            showHideMoreLeg={showHideMoreLeg}
            bShowMoreLeg={checkToShowOrNot(showMoreLeg, setShowMoreLeg, leg)}
            // setModalLeg={setModalLeg}
          />
        ))}
        <hr />
      </Fragment>
    );
  };

  if (loading) return <Spinner />;

  return (
    <Fragment>
      {legs?.length > 0 ? (
        <div className="row" key="legs-row-leglist-1">
          <div className="col" key="legs-row-leglist-1">
            {/* <ShowTotalsRow data={legsNew} /> */}
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="row" key="legs-row-leglist-0">
        <div
          className="col"
          key="legs-row-leglist-0 "
          style={{
            height: "80vh",
            overflow: "auto",
          }}
        >
          {!holiday?._id ? "" : displayLegList(legsNew)}
        </div>
      </div>
      {/* <div>{showMoreLeg()}</div> */}
    </Fragment>
  );
};

export default LegList;
