import { DateTime } from "luxon";
import React from "react";
import Select from "react-select";

// // used in the report filters
// // checks if the key exists in the record and the value (regex) matches
// export const getFieldValue = (record, key, value) => {
//   return record[key] && record[key].match(value);
// };

// // used in the report filters
// // checks an list (object) of values and matches against a list of keys / fields
// export const checkFieldValueFromList = (record, keys, values) => {
//   for (let value of values.values) {
//     const regex = new RegExp(`${value}`, "gi");
//     for (let key of keys) {
//       if (getFieldValue(record, key, regex)) {
//         return true;
//       }
//     }
//   }
//   return false;
// };

// @name    parsePassedDate
// @desc    convert the passed date from api call in yyyymmdd format and transform to date value

export const formatDLDate = (date) => {
  let d = DateTime.fromISO(date);
  return d.isValid ? d.toFormat("dd-MMM-yy") : "";
};

export const fldNotExistNullOrEmptyStr = (fld) => {
  return fld === undefined || fld === null || fld === "";
};

export const fldUpdIfEmp = (fld, val) =>
  fldNotExistNullOrEmptyStr(fld) ? val : fld;

export const BalanceCode = (account) => {
  if (!account) return "missing account";

  let aName = ["bank", "name"].map((val) =>
    account !== null && account[val] !== undefined && account[val]
      ? account[val]
      : ""
  );
  return aName.join(" ");
};

export const changeCase = (text, textCase) => {
  let newText;
  if (text === undefined || text === null || text === "") return "";
  switch (textCase.toLowerCase()) {
    case "upper":
      return text.toUpperCase();
    case "lower":
      return text.toLowerCase();
    case "sentence":
      text = text.split(".");
      newText = text.map(
        (t) => t.substr(0, 1).toUpperCase() + t.substr(1, t.length)
      );
      return newText.join(".");
    case "title":
      text = text.split(" ");
      newText = text.map((t) =>
        t.substr(0, 1).toUpperCase() + t.substr(1, t.length)
          ? t.substr(1, t.length)
          : ""
      );
      return newText.join(" ");
    default:
      return text;
  }
};

export const getValOr0 = (val) =>
  val !== undefined && val !== null ? parseFloat(val) : 0;

export const daysBetweenDates = (date1, date2) => {
  if (
    date1 === undefined ||
    date1 === null ||
    date2 === undefined ||
    date2 === null
  )
    return null;

  let startDate = DateTime.fromISO(date1);
  let endDate = DateTime.fromISO(date2);

  if (startDate.isValid && endDate.isValid) {
    if (startDate === endDate) return 0;
    return startDate < endDate
      ? DateTime.fromISO(startDate).diff(endDate, "days").days
      : DateTime.fromISO(endDate).diff(startDate, "days").days * -1;
  }

  return null;
};

export const getValidDate = (d) => {
  let newDate = DateTime.fromJSDate(d);
  if (!newDate.isValid) newDate = DateTime.fromISO(d);
  if (!newDate.isValid) newDate = DateTime.fromFormat(d, "dd-LLL-yyyy");
  if (!newDate.isValid) newDate = DateTime.fromFormat(d, "dd/LLL/yyyy");
  if (!newDate.isValid) newDate = DateTime.fromFormat(d, "yyyy-LLL-dd");
  return newDate;
};

export const fieldTag = (key, isMobile, data) =>
  isMobile ? (
    <div key={key}>{data}</div>
  ) : (
    <div className="divTableCell" key={key}>
      {data}
    </div>
  );

const buttonClasses = [
  { name: "not paid", class: "danger" },
  { name: "not due", class: "secondary" },
  { name: "paid", class: "success" },
  { name: "open", class: "secondary" },
  { name: "matured", class: "success" },
];

// takes the list of fields, the record
// const fieldArgs = {arrays: arrays, fields: fields, record: null, onchange: onChange, isMobile: isMobile, bAdd: bAdd}

// const propsExist = (item, props) => {
//   if (item === undefined || item === null) {
//     console.log('here1');
//     return false;
//   } else {
//     console.log('here2');
//     console.log(console.log(item['record']));
//     // console.log('here2');
//     let newItem = item;
//     for (let prop in props) {
//       if (newItem[prop] === undefined || newItem[prop] === null) {
//         console.log('here3');
//         return false;
//       } else {
//         console.log('here4');
//         return true;
//         newItem = newItem[prop];
//       }
//     }
//   }
//   return true;
// };

export const returnISODate = (d) => {
  // let dateSeg = d.split('-');
  // let newDate = new Date(dateSeg[0], dateSeg[1], dateSeg[2]);
  // newDate += 5 * 24;

  let newDate = DateTime.fromISO(d);
  return newDate.toISO();
};

export const showFields = (args) =>
  args.fields.map((field) => {
    let tagId = createID(field.field, args.record);

    let new_args = {
      tagId: tagId,
      field: findInObj(args.fields, "field", field.field),
      record: args.record,
      onChange: args.onChange,
      onDateClear: args.onDateClear,
      add: args.add,
    };

    let data;
    if (field.input) {
      if (field.input[0] === "dropdown") {
        let selectTitle = field.input?.[2] ? field.input[2] : "_id";
        data = dropDownInput(
          args.arrays[field.input[1]],
          selectTitle,
          new_args
        );
      } else if (field.input[0] === "button") {
        data = buttonInput(new_args);
      } else {
        data = textInput(new_args);
      }
    } else if (field.type === "date") {
      data = showDatePicker(new_args);
    } else {
      data = textInput(new_args);
    }

    return fieldTag(tagId, args.isMobile, data);
  });

export const buttonInput = (args) => {
  let buttons = args.field.input[1];

  // add, has no record
  // edit, has a record
  // loading only for edit
  return !emptyArr(buttons) &&
    (args.add || objHasKey(args.record, args.field.field)) ? (
    <div className="btn-toolbar" style={{ justifyContent: "center" }}>
      <div
        className="btn-group"
        data-bs-toggle="btn-success"
        key={`btn-group-${args.tagId}`}
      >
        {buttons.map((button) => {
          let buttonClass = findInObj(
            buttonClasses,
            "name",
            button.toLowerCase()
          );
          buttonClass = buttonClass ? buttonClass : "primary";

          // console.log(buttonClass);
          let selected =
            !args.add && args.record[args.field.field] === button
              ? "btn-" + buttonClass.class + " active"
              : "btn-light";

          // 'btn btn-sm btn-' + selected + buttonClass.class,
          return ActionButton("btn btn-sm " + selected, args.onChange, button, [
            args.field.field,
            button,
          ]);
        })}
      </div>
    </div>
  ) : (
    <span key={args.field.field + "-loading"}>still loading</span>
  );
};

export const textInput = (args) => {
  args.inputType = "text";
  let opts = tagOptions(args);
  opts.className = "form-control";

  return (
    <div className="form-group">
      <div className="input-group  input-group-sm">
        <div className="input-group-prepend">
          <span
            style={{ width: "6rem" }}
            className="input-group-text"
            id={`input-addon-${args.tagId}`}
          >
            <small>{args.field.title}</small>
          </span>
        </div>
        <input
          type="text"
          {...opts}
          id={opts.name}
          aria-describedby={`input-addon-${args.tagId}`}
          aria-label={args.field.title}
          aria-labelledby={args.field.title}
          title={args.field.title}
        />
      </div>
    </div>
  );
};

const showDatePicker = (args) => {
  args.inputType = "datepicker";
  let opts = tagOptions(args);
  let clearArgs = { target: { name: args.field.field, value: "" } };

  if (
    !args.add &&
    args.record &&
    args.field &&
    args.field.field &&
    args.record[args.field.field]
  ) {
    let theDate = DateTime.fromISO(args.record[args.field.field]);
    opts.value = theDate.toString().substring(0, 10);
    clearArgs = {
      target: { name: args.field.field, value: "", action: "clear" },
    };
  }

  opts.className = "form-control";
  return (
    // <div className='form-group'>
    <div className="form-group">
      <div className="input-group  input-group-sm">
        <div className="input-group-prepend">
          <span
            className="input-group-text"
            style={{ width: "6rem" }}
            id={`picker-addon-${args.tagId}`}
          >
            <small>{args.field.title}</small>
          </span>
        </div>
        <input
          {...opts}
          id={opts.name}
          type="date"
          aria-describedby={`button-${args.field.field} picker-addon-${args.tagId}`}
          aria-label={args.field.title}
          aria-labelledby={args.field.title}
          title={args.field.title}
        />
        {/* <div className="input-group-append"> */}
        {/* <div className="input-group"> */}
        <button
          type="button"
          key={`button-${args.field.field}`}
          id={`button-${args.field.field}`}
          className="btn btn-secondary"
          onClick={(e, a) => args.onDateClear(clearArgs, "clear")}
        >
          <i className="far fa-window-close	" />
        </button>
        {/* </div> */}
      </div>
    </div>
  );
};

export const dropDownInput = (value_list, titleField, args) => {
  args.inputType = "dropdown";
  // console.log(value_list);

  if (!emptyArr(value_list)) {
    let fieldName = args.field.field;
    let record = args.record;

    let selectList = value_list.map((item) => {
      let opt = { value: item._id, label: item[titleField] };
      return opt;
    });
    let opts = tagOptions(args);
    if (record && record[fieldName] && record[fieldName]._id) {
      record[fieldName].value = record[fieldName]._id;
      record[fieldName].label = record[fieldName][titleField];
    }

    opts.className = "form-control";
    return (
      <div className="form-group">
        <div className="input-group  input-group-sm ">
          <div className="input-group-prepend">
            <span
              className="input-group-text h-100"
              style={{ width: "6rem" }}
              id={`picker-addon-${args.tagId}`}
            >
              <small>{args.field.title}</small>
            </span>
          </div>
          <Select
            style={{ margin: "0rem", width: "100%" }}
            {...opts}
            value={
              record && record[fieldName] && record[fieldName]._id
                ? record[fieldName]
                : "Select ..."
            }
            options={selectList}
          />
        </div>
      </div>
    );
  }

  return <span key={args.field.field + "-loading"}>still loading</span>;
};

const createID = (field, record) => {
  return record !== null && record["_id"] && record["_id"] !== null
    ? field + "-" + record["_id"]
    : field;
};

const tagOptions = (args) => {
  let { field, title } = args.field;
  let opts = {};

  opts.name = field;
  opts.placeholder = title;
  opts.key = field + "-" + args.inputType + "-" + args.tagId;
  opts.onChange = args.onChange;
  // if (args.onBlur) opts.onBlur = args.onBlur;

  // if (!args.add)
  //   opts.value =
  //     objHasKey(args.record, field) && args.record[field] !== null
  //       ? args.record[field]
  //       : '';

  if (!args.add) {
    if (objHasKey(args.record, field) && args.record[field] !== null) {
      opts.value = args.record[field];
    } else {
      opts.value = "";
    }
  }

  return opts;
};

export const ShowValueOrName = (name, type, value) => {
  let v = formatValue(type, value);
  return v === null || v === "" ? name : v;
};

// <SingleDatePicker
//   name={args.field.field}
//   onDateChange={args.onChange}
//   id={args.tagId}
//   date={args.record[args.field.field]}
//   focused={false}
//   onFocusChange={({ focused }) => this.setState({ focused })}
// />
/* <DatePicker
  name={args.field.field}
  onChange={args.onChange}
  className='form-control'
  value={args.record[args.field.field]}
/> */

export const collapseMultiButton = (
  target,
  controls,
  value,
  classname,
  show,
  onclick
) => (
  <button
    onClick={onclick !== null ? () => onclick() : (e) => e.preventDefault()}
    className={classname}
    data-bs-toggle="collapse"
    data-bs-target={target}
    aria-expanded={show}
    aria-controls={controls}
  >
    {value}
  </button>
);

export const collapseButton = (target, value, classname) => (
  <button
    type="button"
    className={"btn btn-link " + classname}
    data-bs-toggle="collapse"
    data-bs-target={"#" + target}
    aria-expanded="false"
    aria-controls="collapseExample"
    style={{ textDecoration: "none" }}
  >
    {value}
  </button>
);

export const ActionButton = (classname, action, name, param) => (
  <button
    type="button"
    key={`button-${name}`}
    className={classname}
    onClick={() => action(param)}
  >
    {name}
  </button>
);

export const modalButton = (
  modalName,
  clickFunction,
  buttonStyle,
  buttonText
) => (
  <button
    className={buttonStyle}
    data-bs-toggle="modal"
    data-bs-target={"#" + modalName}
    onClick={clickFunction}
  >
    {buttonText}
  </button>
);

export const modalDeleteHandler = (deleteFunction, deleteId) => {
  if (deleteId) {
    deleteFunction(deleteId);
    let deleteModal = document.getElementById("closeDeleteModal");
    deleteModal.click();
    return false;
  } else {
    alert("No record to delete.");
  }
};

// export const ActionButton = (classname, action, name, param, id) => {
//   // console.log(name);
//   return (
//     <button
//       type='button'
//       key={`button-${(isObject(name) ? classname : name) + id}`}
//       className={classname}
//       onClick={() => action(param)}
//     >
//       {name}
//     </button>
//   );
// };

export const numStrToNumArr = (string) =>
  string.length === 0 ? [] : string.replace(/, +/g, ",").split(",").map(Number);

export const arrStrToNumArr = (arr) => arr.map((num) => parseInt(num));

export const today = () => DateTime.local();

export const confirmVal = (fld) =>
  fld !== undefined && fld !== null ? fld : null;

export const emptyArr = (arr) => !(arr && arr !== null && arr.length > 0);

export const emptyObj = (obj) => isObject(obj) && Object.keys(obj).length === 0;

export const isObject = (obj) =>
  typeof obj === "object" && !Array.isArray(obj) && obj !== null;

// export const isObject = (value) =>
//   value && typeof value === "object" && value.constructor === Object;

export const getNumber = (val) =>
  val !== undefined && val !== null ? parseFloat(val) : 0;

export const isNumeric = (num) => !isNaN(num);

export const isDate = (d) => {
  if (d !== undefined && d !== null) {
    let m = DateTime.fromISO(d);
    return m.isValid;
  } else {
    return false;
  }
};
// @name  getPeriodDates
// @desc  return current and next period dates (start, end, period)
export const getPeriodDates = (currentPeriod) => {
  const dp = "routes.periods.getPeriodDates";
  try {
    var new_dates = {};
    new_dates.currentPeriod = DateTime.fromISO(currentPeriod);

    // var d = new_dates.currentPeriod.getDate();
    var m = new_dates.currentPeriod.month;
    var y = new_dates.currentPeriod.year;
    new_dates.currentStart = DateTime.local(y, m, 1);
    new_dates.currentEnd = DateTime.local(y, m, getLastDayOfMonth(y, m));
    new_dates.nextPeriod = new_dates.currentPeriod.plus({ month: 1 });
    new_dates.nextStart = new_dates.currentStart.plus({ month: 1 });
    new_dates.nextEnd = new_dates.currentEnd.plus({ month: 1 });

    return new_dates;
  } catch (err) {
    console.error(`${dp}:error: ${err.message}`);
  }
};

// @name    getLastDayOfMonth
// @desc    return last day of month for passed year and month
export const getLastDayOfMonth = (y, m) => {
  return new Date(y, m + 1, 0).getDate();
};

// @name    getNextMonthDate
// @desc    return date for month + 1 of same day
export const getNextMonthDate = (d) => {
  var next_date = new Date(d);
  return new Date(next_date.setMonth(next_date.getMonth() + 1));
};

export const objHasKey = (obj, key) =>
  !emptyObj(obj) && Object.prototype.hasOwnProperty.call(obj, key);

export const buttonCenter = () => (
  <div className="d-flex justify-content-center"></div>
);
export const findInObj = (arr, key, value) => arr.find((o) => o[key] === value);

export const findIndexInObj = (arr, key, value) =>
  arr.findIndex((o) => o[key] === value);

export const isObjectEmpty = (obj) => !(obj !== undefined && obj !== null);

export const isArrayEmpty = (obj) =>
  !(obj !== undefined && obj !== null && obj.length !== 0);

export const isString = (value) =>
  typeof value === "string" || value instanceof String;

export const isEmptyString = (value) =>
  value === undefined && value === null && value === "";

export const notEmptyString = (value) =>
  value !== undefined && value !== null && isString(value);

export const isArray = (value) =>
  value && typeof value === "object" && value.constructor === Array;

export const parsePassedDate = (d) => {
  let p = [
    [0, 4],
    [4, 6],
    [6, 8],
  ];
  let r = p.map((ps) => parseInt(d.toString().substring(ps[0], ps[1])));
  return new Date(r[0], r[1] - 1, r[2], 0, 0, 0);
};

export const numberFormat = (value, dec) =>
  value === null ? "" : parseFloat(value).toFixed(dec);

export const currencyFormat = (value, decs) =>
  value === null
    ? ""
    : new Intl.NumberFormat("en-AU", {
        style: "currency",
        currency: "AUD",
        maximumFractionDigits: decs,
        minimumFractionDigits: decs,
      }).format(value);

export const dateFormat = (value) => {
  return value !== undefined && value !== null && value !== ""
    ? new Intl.DateTimeFormat("en-AU").format(new Date(value))
    : "";
};

export const dateTimeFormat = (value) => {
  let format = {
    dateStyle: "short",
    timeStyle: "short",
  };

  format = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  return value !== undefined && value !== null && value !== ""
    ? new Intl.DateTimeFormat("en-AU", format).format(new Date(value))
    : "";
};

export const formatValue = (type, value) => {
  if (value === undefined || value === null || value === "null") return "";

  switch (type.toLowerCase()) {
    case "date":
      return dateFormat(value);
    case "datetime":
      return dateTimeFormat(value);
    case "number":
      return numberFormat(value, 0);
    case "currency":
      return currencyFormat(value);
    case "float":
      return currencyFormat(value);
    case "array":
      return value.join(",");
    case "string":
    default:
      return value;
  }
};
