import React, { useEffect, useContext } from "react";
import HolidayTag from "../holidays/Holidays";
import AuthContext from "../../context/auth/authContext";

const Holidays = () => {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.loadUser();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="grid-1">
      <HolidayTag />
    </div>
  );
};

export default Holidays;
