export const GET_HOLIDAYS = "GET_HOLIDAYS";
export const ADD_HOLIDAY = "ADD_HOLIDAY";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";
export const CLEAR_HOLIDAYS = "CLEAR_HOLIDAYS";
export const UPDATE_HOLIDAY = "UPDATE_HOLIDAY";
export const GET_LOCATION_REFERENCE = "GET_LOCATION_REFERENCE";
export const FILTER_HOLIDAYS = "FILTER_HOLIDAYS";
export const HOLIDAY_ERROR = "HOLIDAY_ERROR";
export const CLEAR_HOLIDAY_ERRORS = "CLEAR_HOLIDAY_ERRORS";

export const HOLIDAY_ALERT = "HOLIDAY_ALERT";
export const SET_HOLIDAY_ALERT = "SET_HOLIDAY_ALERT";
export const REMOVE_HOLIDAY_ALERT = "REMOVE_HOLIDAY_ALERT";
export const REMOVE_ALL_HOLIDAY_ALERTS = "REMOVE_ALL_HOLIDAY_ALERTS";

export const GET_HOLIDAY_EDITLIST = "GET_HOLIDAY_EDITLIST";
export const CLEAR_EDITLIST = "CLEAR_EDITLIST";
export const UPDATE_MEMBER = "UPDATE_MEMBER";

export const SET_CURRENT_HOLIDAY = "SET_CURRENT";
export const CLEAR_CURRENT_HOLIDAY = "CLEAR_CURRENT";
export const CLEAR_FILTER_HOLIDAY = "CLEAR_FILTER";
// export const SET_ALERT = "SET_ALERT";
// export const REMOVE_ALERT = "REMOVE_ALERT";

export const GET_LEGS = "GET_LEGS";
export const CLEAR_LEGS = "CLEAR_LEGS";
export const GET_LEG = "GET_LEG";
export const CLEAR_LEG = "CLEAR_LEG";
export const ADD_LEG = "ADD_LEG";
export const DELETE_LEG = "DELETE_LEG";
export const UPDATE_LEG = "UPDATE_LEG";
export const FILTER_LEGS = "FILTER_LEGS";
export const LEG_ERROR = "LEG_ERROR";

export const SET_CURRENT_LEG = "SET_CURRENT";
export const CLEAR_CURRENT_LEG = "CLEAR_CURRENT";
export const CLEAR_FILTER_LEG = "CLEAR_FILTER";
// export const SET_ALERT = "SET_ALERT";
// export const REMOVE_ALERT = "REMOVE_ALERT";

export const SET_CURRENT = "SET_CURRENT";
export const CLEAR_CURRENT = "CLEAR_CURRENT";
export const CLEAR_FILTER = "CLEAR_FILTER";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// AUTH CONSTS
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const GET_USERS = "GET_USERS";
export const SEARCH_USERS = "SEARCH_USERS";
export const USER_NOT_LOADED = "USER_NOT_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
