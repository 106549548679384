import React from "react";

const HorizonButtons = ({ idx, k, subTypeButtons, leg, onLegChange }) => {
  const legTypeLower = leg?.legType.toLowerCase() || "transport";
  if (!subTypeButtons?.[legTypeLower]?.length) return "";
  let buttons = subTypeButtons[legTypeLower];
  let horizScrollRow = { overflowX: "auto", whiteSpace: "nowrap" };
  let horizScrollCol = {
    overflowX: "auto",
    whiteSpace: "nowrap",
    display: "inline-block",
    float: "none",
  };
  let k2 = k + "-subtype";

  return (
    <div className={"row p-1"} key={k2 + "-row"} style={horizScrollRow}>
      <div
        className={"col-12 g-1 my-1"}
        key={k2 + "-col"}
        style={horizScrollCol}
      >
        {buttons.map((button) => {
          let subButStyle =
            leg?.subType && button === leg.subType ? "success" : "primary";
          return (
            <button
              key={`${k2 + idx}-btn-${button}`}
              className={`badge rounded-pill me-1 bg-${subButStyle}`}
              style={{
                minWidth: "50px",
                color: "white",
                borderColor: "whitesmoke",
              }}
              onClick={(e) =>
                onLegChange({ target: { id: "subType", value: button } }, idx)
              }
            >
              <span className="h6">
                <small>{button}</small>
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default HorizonButtons;
