import React, { useContext, useEffect, useState } from "react";

import HolidayContext from "../../context/holiday/holidayContext";
import MyHolidayList from "./MyHolidayList";
import LegsMain from "../legs/Legs";

const HolidayTag = () => {
  const holidayContext = useContext(HolidayContext);
  const { getHolidays, currentHoliday } = holidayContext;

  useEffect(() => {
    getHolidays();

    // eslint-disable-next-line
  }, []);

  // const setTheDay = (next) => {
  //   setCurrentDay(
  //     !next ? currentDay.minus({ days: 1 }) : currentDay.plus({ days: 1 })
  //   );
  // };

  const [showLegs, setShowLegs] = useState(0);
  const [showResearch, setShowResearch] = useState(0);

  const showHolidayContent = () => {
    let code = "";
    if (showResearch > 0) {
      code = <LegsMain />;
    }
    if (showLegs > 0) {
      code = <LegsMain />;
    }
    return code;
  };

  return (
    <div className="row" key="legs-row-leglist-1">
      <div className="col" key="legs-col-leglist-1">
        {!currentHoliday ? <MyHolidayList /> : ""}
      </div>
      <div className="col" key="legs-col-leglist-2">
        {showHolidayContent()}
      </div>
    </div>
  );
};

export default HolidayTag;
