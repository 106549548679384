import React, { useReducer } from "react";
import axios from "axios";
import AuthContext from "./authContext";
import authReducer from "./authReducer";
import setAuthToken from "../../utils/setAuthToken";

import {
  GET_USERS,
  SEARCH_USERS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  USER_NOT_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from "../types";

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    users: null,
    usersFound: null,
    user: null,
    error: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // Load User
  const getUsers = async () => {
    // let lm = "ste.loadUser";
    try {
      // try getting user from auth
      const res = await axios.get("/api/auth/users");

      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  // Load User
  const searchUsers = async (search) => {
    // let lm = "ste.loadUser";
    try {
      const params = new URLSearchParams([["search", search]]);

      // try getting user from auth
      const res = await axios.get("/api/auth/users/search", {
        params,
      });

      dispatch({
        type: SEARCH_USERS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  // Load User
  const loadUser = async () => {
    let lm = "ste.loadUser";
    try {
      // if token exists, set token in config
      // console.log(lm + ": Checking for token");
      if (localStorage.token) {
        // console.log(lm + ": Token Exists");
        setAuthToken(localStorage.token);
      } else {
        // console.log(lm + ": Token doesn't exist");
      }

      // try getting user from auth
      const res = await axios.get("/api/auth");
      // console.log(res);

      if (!res.data) {
        // send user to reducer and mark as not authenticated
        dispatch({
          type: USER_NOT_LOADED,
          payload: res.data,
        });
      } else {
        // send user to reducer and mark as authenticated
        dispatch({
          type: USER_LOADED,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  // Register User
  const register = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post("/api/users", formData, config);

      // send to reducer to log token in local storage
      //   and mark as authenticated
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      // load user and return user details
      loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: REGISTER_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  // Login User
  const login = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      // try registering user with form data and json config
      const res = await axios.post("/api/auth", formData, config);

      // send to reducer to log token in local storage
      //   and mark as authenticated
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      // load user and return user details
      loadUser();
    } catch (err) {
      // remove token from storage and mark as not authenticated
      //   and some other stuff
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.msg,
      });
    }
  };

  // Logout User
  const logout = () => {
    dispatch({ type: LOGOUT });
  };

  // Clear Errors
  const clearErrors = () => {
    dispatch({ type: CLEAR_ERRORS });
  };

  return (
    <AuthContext.Provider
      value={{
        users: state.users,
        getUsers,
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        error: state.error,
        usersFound: state.usersFound,
        searchUsers,
        register,
        loadUser,
        login,
        logout,
        clearErrors,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
